<script>
import {defineComponent} from "vue";

import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";

export default defineComponent({
  name: "VideoVisitkaSection",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  props: {
    girls: {
      type: Array,
      require: true
    },
    title: {
      type: String,
      default: "Девушки"
    },
    subtitle: {
      type: String,
      default: "которым мы доверяем"
    }
  },
  methods: {
    getId(id, prefix) {
      return prefix + "_" + id;
    },
    toggleVideo(id){

      let v = document.getElementById('video_'+id);


      let pbb = document.getElementById('playBtnBlockImg_' + id);
      if(v.paused){
        v.play();
        pbb.src = require("@/assets/img/pause-video.svg");
        this.videoTimeShow(id, 'start');
      }else{
        v.pause();
        pbb.src = require("@/assets/img/play-video.svg");
        this.videoTimeShow(id, 'stop');
      }
    },
    toggleMute(id){
      let v = document.getElementById('video_'+id);
      let bm = document.getElementById('btnMute_' + id);

      if(v.muted){
        v.muted = false;
        bm.src = require("@/assets/img/unmute-video.svg");

      }else{
        v.muted = true
        bm.src = require("@/assets/img/mute-video.svg");
      }
    },
    videoTimeShow(id, status){
      let v = document.getElementById('video_'+id);


      if(status == 'start'){
        let int = setInterval(()=>{
          let minBlock = document.getElementById('min_'+id);
          let secBlock = document.getElementById('sec_'+id);

          let progBlock = document.getElementById('videoProgressBlock_' + id);
          let prog = document.getElementById('videoProgress_' + id);
          console.log('progBlock', progBlock.offsetWidth);
          let fullSec = Math.floor(v.currentTime);

          let step = progBlock.offsetWidth / Math.floor(v.duration);

          prog.setAttribute("style","width:" + (step * fullSec) + "px;");


          let sec = fullSec%60;

          let minute = (fullSec - sec) / 60;

          if(sec < 10){sec = "0" + sec;}
          if(minute < 10){minute = "0" + minute;}

          minBlock.innerHTML = minute;
          secBlock.innerHTML = sec;
        }, 1000);
        v.setAttribute('data-int-id', int);
      }else if(status == 'stop'){
        clearInterval( v.getAttribute('data-int-id'));
      }
      console.log(v.getAttribute('data-int-id'))
    }
  },
  data() {

    return {}
  }
});
</script>

<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-auto"><h1>{{ this.title }}</h1></div>
      <div class="col d-flex align-items-center">
        <hr class="w-100">
      </div>
      <div class="col-auto d-flex align-items-center"><span>{{ this.subtitle }}</span></div>
    </div>
    <div class="row mt-5">
      <div class="col-12" v-if="girls?.length > 0">

        <carousel id="homeGirlsCarousel" :items-to-show="4" snap-align="start">
          <slide v-for="girl in this.girls" :key="girl.id">
            <div class="slide position-relative w-100">

              <video :id="getId(girl.id, 'video')" class="w-100" id="video1" muted="" preload="auto">
                <source :src="girl.video" :type="girl.videocodec">
              </video>
              <div class="pult position-absolute d-flex flex-column justify-content-between align-items-center">
                <div class="w-100">
                  <div :id="getId(girl.id, 'videoProgressBlock')" class="progress-block">
                    <span :id="getId(girl.id, 'videoProgress')" class="progress"></span>
                  </div>
                  <div class="time">
                    <span :id="getId(girl.id, 'min')">00</span>:<span :id="getId(girl.id, 'sec')">00</span>
                  </div>
                  <div class="name-btn-block">
                    <div class="name-block">
                      <span>{{ girl.name }}</span>
                    </div>
                    <div class="btns-block">
                      <img src="@/assets/img/close-video.svg" alt="">
                      <img @click="toggleMute(girl.id)" :id="getId(girl.id, 'btnMute')" src="@/assets/img/mute-video.svg" alt="">
                    </div>
                  </div>
                </div>

                <div  class="play-btn-block" @click="toggleVideo(girl.id)">
                  <img :id="getId(girl.id,'playBtnBlockImg')" src="@/assets/img/play-video.svg" alt="">
                </div>
                <button type="button" class="video-btn">Открыть анкету</button>
              </div>


            </div>
          </slide>
          <template #addons>
            <div class="container arrows-categories d-flex justify-content-end mt-5">
              <navigation>

                <template #next>
              <span>
                <img src="@/assets/img/arrow_right.png" alt="">
              </span>
                </template>
                <template #prev>
              <span>
                <img src="@/assets/img/arrow_left.png" alt="">
              </span>
                </template>
              </navigation>
            </div>
          </template>
        </carousel>

      </div>
      <div v-else>
        Добавьте девочек в админке
      </div>

    </div>
  </div>
</template>

<style scoped>
.slide {
  padding: 15px;
}

video {
  border-radius: 25px;
}

.pult {
  left: 30px;
  top: 30px;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  z-index: 5;
}

.progress-block {
  background: rgba(255, 255, 255, 0.30);
  width: 100%;
  height: 3px;
}

.progress {
  background: #FF4032;
  width: 10px;
  height: 3px;
}

.time {
  color: rgba(255, 255, 255, 0.80);
  font-size: 12px;
  font-weight: 600;
}

.name-btn-block {
  display: flex;
  justify-content: space-between;
  text-align: left;
  color: #fff;
}

.name-block {
  width: 50%;
}

.btns-block {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.play-btn-block{
  cursor: pointer;
}

.video-btn {
  display: inline-block;
  max-width: 70%;
  color: #fff;
  border: none;
  border-radius: 26px;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(6.5px);
}
</style>