<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "GirlCompilation",
  props:{
    girlsCompilation: {
      type: Array,
      require: true
    },
    btn:{
      type: Object,
      default: {
        img: require("@/assets/img/girl-online.png"),
        text: "Подробнее"
      }
    }
  },
  data(){

  },
  methods:{
    getBg(index){
      return "background-image: url('"+this.girlsCompilation[index].img+"')";
    }
  }
})
</script>

<template>
  <div class="girlsOftenCompilation">
    <div class="row flex-column">
      <div class="col-12">
        <div class="girl-compilation-block">
          <div class="col-6 radius-left-top girl-compilation-item" :style="getBg(0)">
          </div>
          <div class="col-6 radius-right-top girl-compilation-item "  :style="getBg(1)">
          </div>
          <div class="col-6 radius-left-bottom girl-compilation-item mt-3"  :style="getBg(2)">
          </div>
          <div class="col-6 radius-right-bottom girl-compilation-item mt-3"  :style="getBg(3)">
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <button class="btn btn-grey">
          <img :src="this.btn.img" alt="" class="btn-icon">
          <span class="btn-text">
            {{this.btn.text}}
          </span>
        </button>
      </div>
    </div>
  </div>

</template>

<style scoped>
.radius-left-bottom{
  border-bottom-left-radius: 16px;
  margin-right: 15px;

}
.radius-right-bottom{
  border-bottom-right-radius: 16px;
}
.radius-left-top{
  border-top-left-radius: 16px;
  margin-right: 15px;
}
.radius-right-top{
  border-top-right-radius: 16px;
}
.girl-compilation-block{
  width: 400px;
  display: flex;
  flex-wrap: wrap;
}
.girl-compilation-img{

}

.girlsOftenCompilation  .girl-compilation-item{
  overflow: hidden;
  cursor: pointer;
  width: 185px;
  height: 185px;
  background-position: center;
  background-size: cover;
}
.btn-grey{
  display: flex;
  align-items: center;
  border-radius: 38px;
  background: #EFEFEF;
}

.btn-grey>img{
  margin-right: 5px;

}
</style>