<script setup>

</script>

<template>
  <div class="search-block">
    <input type="text" id="search" name="search" class="search-input">
    <img src="@/assets/img/search.svg">
  </div>


</template>

<style scoped>
.search-block {
  position: relative;
}

.search-input {
  border-radius: 44px;
  background: rgba(255, 255, 255, 0.30);
}

img{
  position: absolute;
  right: 12px;
  top: 8px;
  width: 28px;
  cursor:pointer;
}
</style>