<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "SavedGirls",
  props: {
    savedGirls: {
      type: Array,
      require: true
    },
    online: {
      type: Boolean,
      require: true
    }
  },
  methods: {
    getBg(index) {
      return "background-image: url('" + this.savedGirls[index].img + "')";
    }
  },
  data(){
    console.log('SavedGirls')
  }
})
</script>

<template>
  <div class="girlsSavedCompilation h-100">
    <div v-if="this.online" class="girl-compilation-block">
      <div class="col-6 radius-left-top girl-compilation-item" :style="getBg(0)">
      </div>
      <div class="col-6 radius-right-top girl-compilation-item " :style="getBg(1)">
      </div>
      <div class="col-6 radius-left-bottom girl-compilation-item mt-3" :style="getBg(2)">
      </div>
      <div class="col-6 radius-right-bottom girl-compilation-item mt-3" :style="getBg(3)">
      </div>
    </div>
    <div v-else class="user-offline h-100">
      <div class="row flex-column justify-content-between h-100">
        <div class="col-12 mt-5">
          <div class="row flex-column">
            <div class="col-12">
              <img src="@/assets/img/heart-icon.png" alt="">
            </div>
            <div class="col-12 user-offline-text">
              место для ваших<br> сохранёнок
            </div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <button class="btn btn-gradient">Вход</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.user-offline{
  width: 400px;
  border: 2px dashed #8E7F7D;
  border-radius: 16px;
}

.user-offline-text{
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 600;

}

.btn-gradient{
  border-radius: 32px;
  background: linear-gradient(93deg, #9A7F88 13.27%, #524B5F 48.59%, #201F36 99.15%);
  color: #fff;
}

.radius-left-bottom {
  border-bottom-left-radius: 16px;
  margin-right: 15px;

}

.radius-right-bottom {
  border-bottom-right-radius: 16px;
}

.radius-left-top {
  border-top-left-radius: 16px;
  margin-right: 15px;
}

.radius-right-top {
  border-top-right-radius: 16px;
}

.girl-compilation-block {
  width: 400px;
  display: flex;
  flex-wrap: wrap;
}

.girl-compilation-img {

}

.girlsSavedCompilation .girl-compilation-item {
  overflow: hidden;
  cursor: pointer;
  width: 185px;
  height: 185px;
  background-position: center;
  background-size: cover;
}
</style>