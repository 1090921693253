<script>
import {defineComponent} from "vue";

    export default defineComponent({

    name: "PlaceToggle",
});
</script>

<template>
<div>
    <div class="row justify-content-end">
        <div class="col-auto">
            Екатеринбург
        </div>
        <div class="col-auto text-end ">
            <div class="lang-toggle">
                <span class="lang-active">
                  Ru
                </span>
                <span class="lang-toggler">
                  <img class="toggler-block" src="@/assets/img/toggle.png" alt="">
                  <img class="toggler-dot" src="@/assets/img/toggle_dot.png">
                </span>
                <span>
                  En
                </span>
            </div>

        </div>
    </div>
</div>
</template>

<style scoped>
.lang-toggle{
    color:rgba(45, 47, 58, 0.60);
}
.lang-active{
    color: #2D2F3A;
}
.lang-toggler{

    position:relative;
}
.toggler-block{
    width: 35px;
}
.toggler-dot{
    position:absolute;
    right: 19px;
    top: 5px;
    width: 15px;
}
</style>