<script>
import {defineComponent} from "vue";

import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";

export default defineComponent({
  name: "GirlSection",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  props: {
    girls: {
      type: Array,
      require: true
    },
    title:{
      type: String,
      default: "Девушки"
    },
    subtitle:{
      type: String,
      default: "которым мы доверяем"
    }
  },
  methods:{
    changeGirlImage(girl_id, image_id){
      let girl = this.girls.find(girl => girl.id === girl_id);

      let image = girl.images.find(img => img.id === image_id);
      let imgTag = document.querySelector('#img_' + girl_id);
      imgTag.style.filter = 'blur(6.5px)';
      setTimeout(()=>{imgTag.style.filter = 'none'}, 50);

      imgTag.src = image.link;
    },
    getImgId(id){
      return "img_" + id;
    }
  },
  data() {
    return {}
  }
});
</script>

<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-auto"><h1>{{ this.title }}</h1></div>
      <div class="col d-flex align-items-center">
        <hr class="w-100">
      </div>
      <div class="col-auto d-flex align-items-center"><span>{{ this.subtitle }}</span></div>
    </div>
    <div class="row mt-5">
      <div class="col-12" v-if="girls?.length > 0">

        <carousel id="homeGirlsCarousel" :items-to-show="4"  snap-align="start">
          <slide v-for="girl in this.girls" :key="girl.id">
            <div class="slide">
              <img :id="getImgId(girl.id)" class="big-img" :src="girl.images[0].link">
              <div class="d-flex flex-column justify-content-between girl-desc ">
                <div class="col-12 p-3">
                  <div class="row">
                    <div class="col-6">
                      <div class="row desc">
                        <div class="col-12 girl-name text-start">
                          {{ girl.name }}
                        </div>
                        <div class="col-12 girl-age text-start">
                          {{ girl.age }} года
                        </div>
                        <div class="col-12 text-start girl-place mt-3">метро:</div>
                        <div class="col-12 text-start girl-place">
                          {{ girl.metro }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6 pe-4">
                      <div class="row justify-content-end icons">
                        <div class="col-auto">
                          <img src="@/assets/img/girl-online.png" alt="">
                        </div>
                      </div>
                      <div class="row justify-content-end mt-5" >
                        <div class="col-auto girl-icons ">
                          <div class="row">
                            <div class="col-12 p-2">
                              <img src="@/assets/img/girl-checked.png" alt="">
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 p-2">
                              <img src="@/assets/img/girl-inTop.png" alt="">
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 p-2">
                              <img src="@/assets/img/girl-videoVisitka.png" alt="">
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 p-2">
                              <img src="@/assets/img/girl-translation.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 girl-price pb-3 pt-3">
                  <div class="row">
                    <div class="col-4">
                      за час:<br>{{ girl.hour}} ₽
                    </div>
                    <div class="col-4">
                      за два:<br>{{ girl.two}} ₽
                    </div>
                    <div class="col-4">
                      за ночь:<br>{{ girl.night}} ₽
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div class="slide-nav mt-5">

                <div
                    v-for="i in girl.images"
                    :key="i.id"
                    class="col-3 slide-nav-item p-0"
                    @click="changeGirlImage(girl.id, i.id)"
                >
                  <img :src="i.link" class="w-100" alt="">
                </div>

            </div>
          </slide>
          <template #addons>
            <div class="container arrows-categories d-flex justify-content-end mt-5">
              <navigation>

                <template #next>
              <span>
                <img src="@/assets/img/arrow_right.png" alt="">
              </span>
                </template>
                <template #prev>
              <span>
                <img src="@/assets/img/arrow_left.png" alt="">
              </span>
                </template>
              </navigation>
            </div>
          </template>
        </carousel>

      </div>
      <div v-else>
        Добавьте девочек в админке
      </div>

    </div>
  </div>


</template>

<style scoped>
#homeGirlsCarousel .slide {
  width: calc(100% - 30px);
  position: relative;
}

#homeGirlsCarousel .big-img {
  width: 100%;
  z-index: -1;
  border: 1px solid #E6E6E6;
  border-radius: 25px;
  transition: 300ms all;
}

.girl-desc{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#homeGirlsCarousel .desc {
  color: #fff;
}

#homeGirlsCarousel .girl-name {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

#homeGirlsCarousel .girl-age {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

#homeGirlsCarousel .girl-place {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.girl-icons{
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(6.5px);
  border-radius: 8px;
}

.girl-price{
  color: #fff;
  border-radius: 0px 0px 26px 26px;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(6.5px);

}

.slide-nav{
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
}

.slide-nav-item{
  overflow: hidden;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  cursor: pointer;
}
</style>