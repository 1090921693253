import SiteHeader from "@/components/UI/SiteHeader.vue";
import SearchInput from "@/components/UI/SearchInput.vue";
import HomeCategories from "@/components/UI/HomeCategories.vue";
import GirlsSection from "@/components/UI/GirlsSection.vue";
import GirlCompilation from "@/components/UI/GirlCompilation.vue";
import SavedGirls from "@/components/UI/SavedGirls.vue";
import VideoVisitkaSection from "@/components/UI/VideoVisitkaSection.vue";
import SiteFooter from "@/components/UI/SiteFooter.vue";
import PlaceToggle from "@/components/UI/PlaceToggle.vue";

export default [
    SiteHeader,
    SearchInput,
    HomeCategories,
    GirlsSection,
    GirlCompilation,
    SavedGirls,
    VideoVisitkaSection,
    SiteFooter,
    PlaceToggle
]