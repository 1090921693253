<script>
import {defineComponent} from 'vue'
import HomeCategory from "@/components/UI/HomeCategory.vue";
import 'vue3-carousel/dist/carousel.css'
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel'

export default defineComponent({
  name: "HomeCategories",
  components: {
    HomeCategory,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  props: {
    categories: {
      type: Array,
      require: true
    }
  },
  data() {
    console.log(this.categories);
    return {}

  }
});
</script>

<template>
  <div v-if="categories?.length > 0">

    <carousel :items-to-show="5">
      <slide v-for="category in this.categories" :key="category.id">
        <div class="row mt-5"></div>
        <div class="row mt-5"></div>
        <div class="row mt-5"></div>
        <div class="row w-100 justify-content-end align-items-end">
          <div class="col-auto">
            <div class="home-category-num">{{ category.num }}</div>
          </div>
          <div class="col-auto">
            <img :src="category.img " alt="">
          </div>
        </div>
      </slide>

      <template #addons>
        <div class="container arrows-categories d-flex justify-content-end mt-5">
          <navigation>

            <template #next>
              <span>
                <img src="@/assets/img/arrow_right.png" alt="">
              </span>
            </template>
            <template #prev>
              <span>
                <img src="@/assets/img/arrow_left.png" alt="">
              </span>
            </template>
          </navigation>
        </div>
      </template>
    </carousel>

  </div>
  <div v-else>
    Добавьте категории в админке
  </div>
</template>

<style >
.carousel__slide{
  border-right: 1px solid #E6E6E6;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.home-category-num{
  color: #BDC1D1;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.arrows-categories>.carousel__prev, .carousel__next {
  position: relative;
}
</style>