<script>
import {defineComponent} from 'vue'
export default defineComponent({
  name: "HomeCategory",
  props:{
    category:{
      type: Object,
      required:true
    }
  }
})
</script>

<template>
  <div class="home-category">

  </div>
</template>

<style scoped>

</style>