<template>
  <site-header></site-header>
  <home-categories :categories="categories"></home-categories>
  <girl-section :girls="girls.girls"></girl-section>

  <div class="container">
    <div class="row">
      <div class="col-auto"><h1>Подборки</h1></div>
      <div class="col d-flex align-items-center">
        <hr class="w-100">
      </div>
      <div class="col-auto d-flex align-items-center"><span>для вашего удобства и быстрого поиска</span></div>
    </div>
    <div class="row justify-content-between mt-5">
      <div class="col-auto">
        <girl-compilation :girlsCompilation="girlsOftenCompilation.girls" :btn="girlsOftenCompilation.btn"></girl-compilation>
      </div>
      <div class="col-auto">
        <girl-compilation :girlsCompilation="girlsOftenCompilation.girls" :btn="girlsOftenCompilation.btn"></girl-compilation>
      </div>
      <div class="col-auto">
        <girl-compilation :girlsCompilation="girlsOftenCompilation.girls" :btn="girlsOftenCompilation.btn"></girl-compilation>
      </div>
      <div class="col-auto">
        <saved-girls :savedGirls="girlsOftenCompilation.girls" :online="user.online"></saved-girls>
      </div>
    </div>
  </div>

  <girl-section :girls="saloon.girls" :title="saloon.title" :subtitle="saloon.subtitle"></girl-section>
<VideoVisitkaSection :girls="videoVisitka.girls" :title="videoVisitka.title" :subtitle="videoVisitka.subtitle"></VideoVisitkaSection>
  <div class="row mt-5"></div>
  <site-footer></site-footer>


</template>

<style>
body{
  font-family: 'Montserrat', sans-serif;
  color: #484C5E;
  font-size: 20px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

h1{
  color: #2D2F3A;
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}


@media (min-width: 1400px){
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1700px;
  }
}
</style>
<script>
import SiteHeader from "@/components/UI/SiteHeader.vue";
import HomeCategories from "@/components/UI/HomeCategories.vue";
import GirlSection from "@/components/UI/GirlsSection.vue";
import GirlCompilation from "@/components/UI/GirlCompilation.vue";
import SavedGirls from "@/components/UI/SavedGirls.vue";
import VideoVisitkaSection from "@/components/UI/VideoVisitkaSection.vue";
import SiteFooter from "@/components/UI/SiteFooter.vue";


export default {
  components: {
    SiteFooter,
    VideoVisitkaSection,
    SavedGirls,
    GirlCompilation,
    GirlSection,
    HomeCategories,
    SiteHeader,

  },
  data(){
    return{
        user:{
          username: "PayMarket",
          online: false,
          savedGirls: [
            {id:1, img: require("@/assets/img/dev/girls/g1_2.png")},
            {id:1, img: require("@/assets/img/dev/girls/g1_1.png")},
            {id:1, img: require("@/assets/img/dev/girls/g1_2.png")},
            {id:1, img: require("@/assets/img/dev/girls/g1_1.png")},
          ]
        },
      categories:[
        {id:1, name: "Содержанки", num: "01", img: require("@/assets/img/01.svg")},
        {id:2, name: "Инди", num: "02", img: require("@/assets/img/02.svg")},
        {id:3, name: "Эскорт", num: "03", img: require("@/assets/img/03.svg")},
        {id:4, name: "TS", num: "04", img: require("@/assets/img/04.svg")},
        {id:5, name: "Салоны", num: "05", img: require("@/assets/img/05.svg")},
        {id:6, name: "Вебкам", num: "06", img: require("@/assets/img/06.svg")},
        {id:7, name: "Мужчины", num: "07", img: require("@/assets/img/07.svg")}
      ],
      girls: {
        title: "",
        subtitle: "",
        girls:[
          {
            id:1,
            name: "Анжела",
            age: 33,
            metro: "Арбатская",
            online: true,
            hour: 10000,
            two: 15000,
            night: 30000,
            checked: true,
            inTop:false,
            videoVisitka: true,
            translation: false,
            images:[
              {
                id: 1,
                link: require("@/assets/img/dev/girls/g1_1.png"),
                alt: "alt"
              },
              {
                id: 2,
                link: require("@/assets/img/dev/girls/g1_2.png"),
                alt: "alt"
              },
              {
                id: 3,
                link: require("@/assets/img/dev/girls/g1_1.png"),
                alt: "alt"
              },
              {
                id: 4,
                link: require("@/assets/img/dev/girls/g1_2.png"),
                alt: "alt"
              },
            ]
          },
          {
            id:2,
            name: "Анжела",
            age: 33,
            metro: "Арбатская",
            online: true,
            hour: 10000,
            two: 15000,
            night: 30000,
            checked: true,
            inTop:false,
            videoVisitka: true,
            translation: false,
            images:[
              {
                id: 1,
                link: require("@/assets/img/dev/girls/g1_1.png"),
                alt: "alt"
              },
              {
                id: 2,
                link: require("@/assets/img/dev/girls/g1_2.png"),
                alt: "alt"
              },
              {
                id: 3,
                link: require("@/assets/img/dev/girls/g1_1.png"),
                alt: "alt"
              },
              {
                id: 4,
                link: require("@/assets/img/dev/girls/g1_2.png"),
                alt: "alt"
              },
            ]
          },
          {
            id:3,
            name: "Анжела",
            age: 33,
            metro: "Арбатская",
            online: true,
            hour: 10000,
            two: 15000,
            night: 30000,
            checked: true,
            inTop:false,
            videoVisitka: true,
            translation: false,
            images:[
              {
                id: 1,
                link: require("@/assets/img/dev/girls/g1_1.png"),
                alt: "alt"
              },
              {
                id: 2,
                link: require("@/assets/img/dev/girls/g1_2.png"),
                alt: "alt"
              },
              {
                id: 3,
                link: require("@/assets/img/dev/girls/g1_1.png"),
                alt: "alt"
              },
              {
                id: 4,
                link: require("@/assets/img/dev/girls/g1_2.png"),
                alt: "alt"
              },
            ]
          },
          {
            id:4,
            name: "Анжела",
            age: 33,
            metro: "Арбатская",
            online: true,
            hour: 10000,
            two: 15000,
            night: 30000,
            checked: true,
            inTop:false,
            videoVisitka: true,
            translation: false,
            images:[
              {
                id: 1,
                link: require("@/assets/img/dev/girls/g1_1.png"),
                alt: "alt"
              },
              {
                id: 2,
                link: require("@/assets/img/dev/girls/g1_2.png"),
                alt: "alt"
              },
              {
                id: 3,
                link: require("@/assets/img/dev/girls/g1_1.png"),
                alt: "alt"
              },
              {
                id: 4,
                link: require("@/assets/img/dev/girls/g1_2.png"),
                alt: "alt"
              },
            ]
          },
          {
            id:5,
            name: "Анжела",
            age: 33,
            metro: "Арбатская",
            online: true,
            hour: 10000,
            two: 15000,
            night: 30000,
            checked: true,
            inTop:false,
            videoVisitka: true,
            translation: false,
            images:[
              {
                id: 1,
                link: require("@/assets/img/dev/girls/g1_1.png"),
                alt: "alt"
              },
              {
                id: 2,
                link: require("@/assets/img/dev/girls/g1_2.png"),
                alt: "alt"
              },
              {
                id: 3,
                link: require("@/assets/img/dev/girls/g1_1.png"),
                alt: "alt"
              },
              {
                id: 4,
                link: require("@/assets/img/dev/girls/g1_2.png"),
                alt: "alt"
              },
            ]
          },
          {
            id:6,
            name: "Анжела",
            age: 33,
            metro: "Арбатская",
            online: true,
            hour: 10000,
            two: 15000,
            night: 30000,
            checked: true,
            inTop:false,
            videoVisitka: true,
            translation: false,
            images:[
              {
                id: 1,
                link: require("@/assets/img/dev/girls/g1_1.png"),
                alt: "alt"
              },
              {
                id: 2,
                link: require("@/assets/img/dev/girls/g1_2.png"),
                alt: "alt"
              },
              {
                id: 3,
                link: require("@/assets/img/dev/girls/g1_1.png"),
                alt: "alt"
              },
              {
                id: 4,
                link: require("@/assets/img/dev/girls/g1_2.png"),
                alt: "alt"
              },
            ]
          },
          {
            id:7,
            name: "Анжела",
            age: 33,
            metro: "Арбатская",
            online: true,
            hour: 10000,
            two: 15000,
            night: 30000,
            checked: true,
            inTop:false,
            videoVisitka: true,
            translation: false,
            images:[
              {
                id: 1,
                link: require("@/assets/img/dev/girls/g1_1.png"),
                alt: "alt"
              },
              {
                id: 2,
                link: require("@/assets/img/dev/girls/g1_2.png"),
                alt: "alt"
              },
              {
                id: 3,
                link: require("@/assets/img/dev/girls/g1_1.png"),
                alt: "alt"
              },
              {
                id: 4,
                link: require("@/assets/img/dev/girls/g1_2.png"),
                alt: "alt"
              },
            ]
          }
        ]
      },
      girlsOftenCompilation:{
        girls:[
          {id:1, img: require("@/assets/img/dev/girls/g1_2.png")},
          {id:1, img: require("@/assets/img/dev/girls/g1_1.png")},
          {id:1, img: require("@/assets/img/dev/girls/g1_2.png")},
          {id:1, img: require("@/assets/img/dev/girls/g1_1.png")},
        ],
        btn:{
          img: require("@/assets/img/girl-online.png"),
          text: "Часто выбирают"
        }
      },
      saloon: {
          title: "Салоны",
          subtitle: "в которые мы бы пошли сами",
          girls:[
            {
              id:1,
              name: "Анжела",
              age: 33,
              metro: "Арбатская",
              online: true,
              hour: 10000,
              two: 15000,
              night: 30000,
              checked: true,
              inTop:false,
              videoVisitka: true,
              translation: false,
              images:[
                {
                  id: 1,
                  link: require("@/assets/img/dev/girls/g1_1.png"),
                  alt: "alt"
                },
                {
                  id: 2,
                  link: require("@/assets/img/dev/girls/g1_2.png"),
                  alt: "alt"
                },
                {
                  id: 3,
                  link: require("@/assets/img/dev/girls/g1_1.png"),
                  alt: "alt"
                },
                {
                  id: 4,
                  link: require("@/assets/img/dev/girls/g1_2.png"),
                  alt: "alt"
                },
              ]
            },
            {
              id:2,
              name: "Анжела",
              age: 33,
              metro: "Арбатская",
              online: true,
              hour: 10000,
              two: 15000,
              night: 30000,
              checked: true,
              inTop:false,
              videoVisitka: true,
              translation: false,
              images:[
                {
                  id: 1,
                  link: require("@/assets/img/dev/girls/g1_1.png"),
                  alt: "alt"
                },
                {
                  id: 2,
                  link: require("@/assets/img/dev/girls/g1_2.png"),
                  alt: "alt"
                },
                {
                  id: 3,
                  link: require("@/assets/img/dev/girls/g1_1.png"),
                  alt: "alt"
                },
                {
                  id: 4,
                  link: require("@/assets/img/dev/girls/g1_2.png"),
                  alt: "alt"
                },
              ]
            },
            {
              id:3,
              name: "Анжела",
              age: 33,
              metro: "Арбатская",
              online: true,
              hour: 10000,
              two: 15000,
              night: 30000,
              checked: true,
              inTop:false,
              videoVisitka: true,
              translation: false,
              images:[
                {
                  id: 1,
                  link: require("@/assets/img/dev/girls/g1_1.png"),
                  alt: "alt"
                },
                {
                  id: 2,
                  link: require("@/assets/img/dev/girls/g1_2.png"),
                  alt: "alt"
                },
                {
                  id: 3,
                  link: require("@/assets/img/dev/girls/g1_1.png"),
                  alt: "alt"
                },
                {
                  id: 4,
                  link: require("@/assets/img/dev/girls/g1_2.png"),
                  alt: "alt"
                },
              ]
            },
            {
              id:4,
              name: "Анжела",
              age: 33,
              metro: "Арбатская",
              online: true,
              hour: 10000,
              two: 15000,
              night: 30000,
              checked: true,
              inTop:false,
              videoVisitka: true,
              translation: false,
              images:[
                {
                  id: 1,
                  link: require("@/assets/img/dev/girls/g1_1.png"),
                  alt: "alt"
                },
                {
                  id: 2,
                  link: require("@/assets/img/dev/girls/g1_2.png"),
                  alt: "alt"
                },
                {
                  id: 3,
                  link: require("@/assets/img/dev/girls/g1_1.png"),
                  alt: "alt"
                },
                {
                  id: 4,
                  link: require("@/assets/img/dev/girls/g1_2.png"),
                  alt: "alt"
                },
              ]
            },
            {
              id:5,
              name: "Анжела",
              age: 33,
              metro: "Арбатская",
              online: true,
              hour: 10000,
              two: 15000,
              night: 30000,
              checked: true,
              inTop:false,
              videoVisitka: true,
              translation: false,
              images:[
                {
                  id: 1,
                  link: require("@/assets/img/dev/girls/g1_1.png"),
                  alt: "alt"
                },
                {
                  id: 2,
                  link: require("@/assets/img/dev/girls/g1_2.png"),
                  alt: "alt"
                },
                {
                  id: 3,
                  link: require("@/assets/img/dev/girls/g1_1.png"),
                  alt: "alt"
                },
                {
                  id: 4,
                  link: require("@/assets/img/dev/girls/g1_2.png"),
                  alt: "alt"
                },
              ]
            },
            {
              id:6,
              name: "Анжела",
              age: 33,
              metro: "Арбатская",
              online: true,
              hour: 10000,
              two: 15000,
              night: 30000,
              checked: true,
              inTop:false,
              videoVisitka: true,
              translation: false,
              images:[
                {
                  id: 1,
                  link: require("@/assets/img/dev/girls/g1_1.png"),
                  alt: "alt"
                },
                {
                  id: 2,
                  link: require("@/assets/img/dev/girls/g1_2.png"),
                  alt: "alt"
                },
                {
                  id: 3,
                  link: require("@/assets/img/dev/girls/g1_1.png"),
                  alt: "alt"
                },
                {
                  id: 4,
                  link: require("@/assets/img/dev/girls/g1_2.png"),
                  alt: "alt"
                },
              ]
            },
            {
              id:7,
              name: "Анжела",
              age: 33,
              metro: "Арбатская",
              online: true,
              hour: 10000,
              two: 15000,
              night: 30000,
              checked: true,
              inTop:false,
              videoVisitka: true,
              translation: false,
              images:[
                {
                  id: 1,
                  link: require("@/assets/img/dev/girls/g1_1.png"),
                  alt: "alt"
                },
                {
                  id: 2,
                  link: require("@/assets/img/dev/girls/g1_2.png"),
                  alt: "alt"
                },
                {
                  id: 3,
                  link: require("@/assets/img/dev/girls/g1_1.png"),
                  alt: "alt"
                },
                {
                  id: 4,
                  link: require("@/assets/img/dev/girls/g1_2.png"),
                  alt: "alt"
                },
              ]
            }
          ]
      },
      videoVisitka:{
          girls:[
            {
              id:1,
              video:require("@/assets/img/dev/girls/video.mp4"),
              videocodec: "video/mp4",
              poster: require("@/assets/img/dev/girls/g1_1.png"),
              name: "Анжела",
            },
            {
              id:2,
              video:require("@/assets/img/dev/girls/video.mp4"),
              videocodec: "video/mp4",
              poster: require("@/assets/img/dev/girls/g1_1.png"),
              name: "Анжела",
            },
            {
              id:3,
              video:require("@/assets/img/dev/girls/video.mp4"),
              videocodec: "video/mp4",
              poster: require("@/assets/img/dev/girls/g1_1.png"),
              name: "Анжела",
            },
            {
              id:4,
              video:require("@/assets/img/dev/girls/video.mp4"),
              videocodec: "video/mp4",
              poster: require("@/assets/img/dev/girls/g1_1.png"),
              name: "Анжела",
            },
            {
              id:5,
              video:require("@/assets/img/dev/girls/video.mp4"),
              videocodec: "video/mp4",
              poster: require("@/assets/img/dev/girls/g1_1.png"),
              name: "Анжела",
            },
          ],
        title: "Видеовизитки",
        subtitle: "наших роскошных девушек",
      }
    };
  }
}
</script>